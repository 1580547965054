.wash-marker {
  display: flex;
  font-family: 'CircularStd-Bold';
  font-size: 14px;
  background-color: aquamarine;
}

.wash-marker:hover .ease-out {
  opacity: 1;
  max-width: 200px;
  visibility: visible;
}

.ease-out {
  opacity: 0;
  visibility: hidden;
  max-width: 0;
  transition: all 0.5s ease-out;
}

.car-img {
  width: 17.5px;
  height: 20px;
}

.car-icon {
  background: #041e35;
  padding: 6px 10px;
  border-radius: 50px;
  &.selected {
    background-color: #ffc700;
  }
}

.marker-wrapper {
  display: flex;
  margin-left: 4px;
  height: 30px;
  align-items: center;
  padding: 4px 16px 4px 4px;
  background-color: #fff;
  border-radius: 7px;
  z-index: 2;
}

.marker-rating {
  color: #fff;
  padding: 4px 5px 3px 6px;
  background-color: #006dbe;
  border-radius: 7px;
}

.marker-name {
  margin-left: 6px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marker-distance {
  margin-left: 6px;
  white-space: nowrap;
  background: rgba(102, 102, 102, 0.3);
  padding: 2px 3px;
  border-radius: 4px;
  font-size: 12px;
  align-items: baseline;
}