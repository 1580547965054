.footer-container {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  padding: 0px 20px;
  background-color: white;
  border-top: 1px solid #f9f9f9;
}

.footer-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.footer-nav-item {
  list-style: none;
  margin-right: 20px;
}

.footer-nav-item a {
  color: black;
  font-size: 15px;
  font-family: 'CircularStd-Book';
}

.footer-bottom-container p {
  font-family: 'CircularStd-Book';
}

.footer-nav-item {
  text-decoration: none;
}

.footer-button {
  width: 120px;
}

.footer-logo {
  padding-top: 5px;
  width: 110px;
}

.footer-bottom-container {
  background-color: white;
  text-align: center;
  color: black;
  padding: 20px;
}

@media only screen and (max-width: 800px) {
  .footer-container {
    flex-direction: column;
  }

  .footer {
    text-align: center;
  }

  .footer-button {
    width: 100px;
  }
}
