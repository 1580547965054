.result {
  min-width: 300px;
  border-radius: 39px;
  height: 100%;
  background-color: #fff;
  padding-top: 22px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-header {
    text-align: center;
    color: #b4b4b4;
    font-family: 'CircularStd-Medium';
  }
  &-body {
    margin: 30px 16px 0px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    @media screen and (max-width: 800px) {
      max-height: 250px;
    }
  }
  &-footer {
    margin-left: 16px;
    margin-right: 16px;

    &-content {
      display: flex;
      justify-content: space-between;
    }
  }
}
