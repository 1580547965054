.filter {
    min-width: 300px;
    border-radius: 39px;
    height: 100%;
    background-color: #fff;
    padding-top: 22px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-header {
      text-align: center;
      color: #b4b4b4;
      font-family: 'CircularStd-Medium';
    }
    &-body {
      margin: 10px 16px 0px;
      max-height: calc(100vh - 300px);
      overflow-y: auto;
      @media screen and (max-width: 800px) {
        max-height: 250px;
      }
      
    }
    &-footer {
      margin-left: 16px;
      margin-right: 16px;
  
      &-content {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
  
  .filter-item {
    border-bottom: solid 1px #DFDFDF;
    display: flex;
    position: relative;
    padding: 22px;
    cursor: pointer;
    &-status {
      border-radius: 50px;
      width: 24px;
      height: 24px;
      background-color: rgba(0, 109, 190, 0.2);
      &.selected {
        background-color: #006DBE;
      }
    }
    &-name {
      font-family: 'CircularStd-Medium';
      color: #788A9A;
      margin-left: 12px;
      font-size: 18px;
    }
    &-count {
      font-family: 'CircularStd-Book';
      color: #788A9A;
      font-size: 24px;
      position: absolute;
      right: 20px;
    }
  }