.map-container {
  height: calc(100vh - 110px);
  width: 100%;
  @media screen and (max-width: 800px) {
    height: calc(100vh - 50px);
  }
}

.car-wash-wrapper {
  max-width: 390px;
  position: absolute;
  top: 70px;
  right: 8px;
  @media screen and (max-width: 800px) {
    top: auto;
    bottom: 16px;
  }
  z-index: 3;
  @media screen and (max-width: 425px) {
    right: 0px;
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

.center-marker {
  width: 24px;
  height: 24px;
  border: solid 5px #006dbe;
  border-radius: 50px;
}

.current-center-marker {
  left: -13px;
  top: -13px;
  position: absolute;
}

.destination-marker {
  width: 50px;
  height: 50px;
  border: solid 5px #006dbe;
  border-radius: 50px;
  left: -60px;
  top: -8px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc700;
  &-star {
    width: 24px;
    height: 24px;
    background-color: '#fff';
  }
}

.confirm-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
}

.confirm {
  margin-top: 60px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, -50%);
  z-index: 31;
  background: white;
  padding: 8px;
  border-radius: 25px;
  font-family: 'CircularStd-Medium';
}


.confirm-hover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  white-space: nowrap;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, 5%);
  z-index: 31;
  background: white;
  padding: 8px;
  border-radius: 25px;
  font-family: 'CircularStd-Medium';
  transition: all 0.5s ease-out;
}
