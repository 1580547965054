.zip-code-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.zip-code-container {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hide {
  display: none;
}

.zip-code-form-container {
  display: flex;
  flex-direction: column;
  width: 350px;
  padding: 46px 24px 33px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 15px 45px #0f242f;
  @media only screen and (max-width: 800px) {
    width: 300px;
  }

  @media only screen and (max-width: 320px) {
    width: 260px;
    padding: 24px 12px;
  }
}

.zip-code-form {
  display: flex;
  flex-direction: column;
}

.zip-welcome {
  text-align: left;
  font-family: 'CircularStd-Bold';
  font-size: 12px;
  color: #006dbe;
  margin-bottom: 15px;
}

.zip-title {
  text-align: left;
  font-family: 'CircularStd-Bold';
  margin-bottom: 40px;
}

.zip-code-input-lable {
  font-family: 'CircularStd-Book';
  font-size: 14px;
  color: rgb(159, 181, 194);
  font-weight: 300;
  margin-bottom: 30px;
  text-align: left;
}

.zip-code-form-button {
  background-color: #006dbe;
  color: white;
  font-family: 'CircularStd-Medium';
  padding: 15px;
  border-radius: 30px;
  font-size: 15px;
  border: none;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.zip-welcome {
  text-align: center;
}

.zip-title {
  text-align: center;
  font-size: 32px;
  @media only screen and (max-width: 320px) {
    font-size: 28px;
  }
}
