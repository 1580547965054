section {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0 auto;
  max-width: 600px;
  color: #333;
}

.map-section {
  width: 100%;
  max-width: 100%;
  min-width: 320px;
  border-radius: 35px;
}

h2 {
  font-weight: 900;
  font-size: 32px;
  line-height: 1;
  margin: 0 0 4px;
  display: inline-block;
  vertical-align: top;
}

p {
  margin: 1em 0;
}

a {
  text-decoration: none;
  color: #1ea7fd;
}

ul {
  padding-left: 30px;
  margin: 1em 0;
}

li {
  margin-bottom: 8px;
}

.tip {
  display: inline-block;
  border-radius: 1em;
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  background: #e7fdd8;
  color: #66bf3c;
  padding: 4px 12px;
  margin-right: 10px;
  vertical-align: top;
}

.tip-wrapper {
  font-size: 13px;
  line-height: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.tip-wrapper svg {
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 4px;
  vertical-align: top;
  margin-top: 3px;
}

.tip-wrapper svg path {
  fill: #1ea7fd;
}

.carwash-result-wrapper {
  position: absolute;
  top: 70px;
  right: 16px;
  height: calc(100vh - 140px);
  width: 383px;
  z-index: 3;
  @media screen and (max-width: 800px) {
    height: 400px;
    bottom: 16px;
    top: auto;
  }
  @media screen and (max-width: 425px) {
    right: 0px;
    width: 100%;
    justify-content: center;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.mini-search-toggle {
  position: absolute;
  border-radius: 50px;
  z-index: 3;
  position: absolute;
  top: 80px;
  left: 25px;
  align-items: center;
  display: flex;
  margin-right: 25px;
  font-size: 23px;
  font-family: 'CircularStd-Medium';
  color: white;
  background-color: #006dbe;
  padding: 16px;
  border: solid 6px white;
  cursor: pointer;
}

.filter-toggler {
  position: absolute;
  top: 80px;
  right: 20px;
  padding: 20px;
  background: white;
  border-radius: 50px;
  z-index: 2;
  cursor: pointer;
}

.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.5);
}

.loading {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}