@font-face {
  font-family: 'CircularStd-Medium';
  src: url('./assets/font/CircularStd/CircularStd-Medium.otf');
}
@font-face {
  font-family: 'CircularStd-Book';
  src: url('./assets/font/CircularStd/CircularStd-Book.otf');
}

@font-face {
  font-family: 'CircularStd-Bold';
  src: url('./assets/font/CircularStd/CircularStd-Bold.otf');
}
