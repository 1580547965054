.mini-search {
  width: auto;
  background-color: white;
  border-radius: 50px;
  z-index: 3;
  position: absolute;
  top: 80px;
  left: 25px;
  align-items: center;
  display: flex;
  margin-right: 25px;
  font-size: 14px;
  &-wrapper {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    align-self: center;
  }
}



.mini-search-button {
  background-color: #006dbe;
  width: 110px;
  height: 50px;
  color: white;
  font-family: 'CircularStd-Book';
  border-radius: 30px;
  font-size: 15px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
}