.list-item {
  display: flex;
  position: relative;
  padding: 22px 0px;
  border-bottom: solid 1px #DFDFDF;
  cursor: pointer;
  &:first-child {
    padding-top: 0px;
  }
  .rating {
    color: #fff;
    padding: 4px 5px 3px 6px;
    background-color: #006dbe;
    border-radius: 7px;
    line-height: 24px;
    height: fit-content;
    min-width: 34px;
    text-align: center;
    &.none {
      background-color: grey;
    }
  }
  .content {
    .top {
      display: flex;
      height: 35px;
      align-items: center;
      font-family: 'CircularStd-Bold';
      .name {
        font-size: 24px;
        margin-left: 10px;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: 375px) {
          max-width: 120px;
        }
      }
      .status {
        margin-left: 12px;
        height: 22px;
        vertical-align: center;
        color: #fff;
        padding: 2px 9px 3px 5px;
        border-radius: 5px;
        align-self: flex-start;
        &.open {
          background-color: #6dbe00;
        }
        &.closed {
          background-color: #be0000;
        }
      }
    }
    .bottom {
      margin-left: 10px;
      font-size: 16px;
      display: flex;
      .address {
        color: #9d9d9d;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: 375px) {
          max-width: 150px;
        }
      }
    }
  }
}

.right-arrow {
  width: 11px;
  height: 20px;
  align-self: center;
  float: right;
  position: absolute;
  right: 7px;
}
