.wrapper {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 10px 20px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 425px) {
    margin-top: 5px;
  }
}

svg {
  display: inline-block;
  vertical-align: top;
}

h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: top;
}

button + button {
  margin-left: 10px;
}

@media only screen and (max-width: 800px) {
  .wrapper {
    justify-content: center;
    padding: 0 20px 5px;
  }
}
