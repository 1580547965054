.car-wash {
  border-radius: 39px;
  background-color: #fff;
  padding: 8px;
  padding: 23px 21px;
  width: fit-content;
  margin-left: 8px;
  margin-right: 8px;
  img {
    cursor: pointer;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    min-height: 160px;
  }
  &--logo {
    border-radius: 39px;
    width: 160px;
    height: 160px;
    @media screen and (max-width: 800px) {
      width: 160px;
    }
  }
  &-close {
    background-color: #006dbe;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    margin-left: 14px;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  &-name {
    margin-top: 16px;
    margin-left: 13px;
    display: flex;
    justify-content: space-between;
    &--label {
      .name {
        font-size: 32px;
        font-family: 'CircularStd-Bold';
        max-width: 250px;
      }
    }
    &--status {
      display: flex;
      border-radius: 5px;
      color: #7e7e7e;
      background-color: rgba(#e4e4e4, 0.7);
      padding: 0px 6px 0px 0px;
      width: fit-content;
      font-family: 'CircularStd-Book';
      font-size: 13px;
      align-items: center;
      margin-top: 5px;
      p {
        background-color: #e4e4e4;
        border-radius: 5px;
        margin: 0px 6px 0px 0px;
        padding: 2px 9px 2px 6px;
        font-family: 'CircularStd-Bold';
      }
    }
  }
  &-package {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    color: #006dbe;
    border: solid 1px #006dbe;
    border-radius: 28.5px;
    width: 116px;
    height: 43px;
    font-size: 18px;
    font-family: 'CircularStd-Medium';
  }
  &-actions {
    display: flex;
    margin-top: 16px;
    margin-left: 13px;
    @media screen and (max-width: 800px) {
      display: none;
    }
    &-mobile {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      margin-left: 50px;
      @media screen and (min-width: 800px) {
        display: none;
      }
    }
    &--phone {
      width: 24px;
      height: 24px;
    }
    &--back {
      width: 33px;
      height: 24px;
      align-self: center;
      margin-bottom: 30px;
    }
  }
  &-details {
    color: #939393;
    font-size: 18px;
    font-family: 'CircularStd-Book';
    p {
      margin: 0;
      font-family: 'CircularStd-Medium';
    }
  }
}

.close-icon {
  width: 25px;
  height: 25px;
  margin: 18px;
}

.phone-wrapper {
  background-color: #6dbe00;
  border: none !important;
}

.icon-wrapper {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: solid 2px #788a9a;
  margin-right: 8px;
  @media screen and (max-width: 800px) {
    margin-top: 5px;
    margin-right: 0px;
  }
}

.divider {
  border: solid 0.5px #e0e0e0;
  margin-top: 16px;
  margin-bottom: 14px;
}

.logo-container .fallback {
  background-image: url('../../../images/car-wash.svg');
  background-color: cornflowerblue;
  display: inline-block; /*to ensure it wraps correctly around the image, even if it is a a or span tag*/
  min-width: 260px;
  min-height: 200px;
  background-position: center center; // fallback for older browsers
  background-size: contain;
  background-repeat: no-repeat;
}
