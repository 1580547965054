.btn-normal {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28.5px;
  font-size: 18px;
  margin-left: 3px;
  margin-right: 3px;
  width: 100%;
  height: 57px;
  font-family: 'CircularStd-Medium';
  cursor: pointer;
}
